import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';
import cors from 'cors'
import VueGtag from 'vue-gtag';
import {createHead} from '@vueuse/head'
import VueSocialSharing from 'vue-social-sharing'

createApp(App).use(createHead()).use(router).use(cors).use(VueGtag, {config:  { 
    id : (process.env.VUE_APP_GOOGLE_ANALYTIC_KEY).toString()//"G-XCJFLTJPVS"
}}).use(VueSocialSharing).mount('#app');
