<template>
    <div class="row">
        <div class="col-lg-3 col-sm-1 col-md-2 col-xs-1 col-1"></div>
        <div class="col-lg-6 col-sm-10 col-md-8 col-xs-10 col-10">
            <div class="MainContentSinglePage" :style="{backgroundImage : personalCandidate.CssMedia.BackgroundColorCss}">
                <br />
                <div class="CompanyLogoSection row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-2"></div>
                    <div class="CompanyLogoSection col-lg-4 col-md-4 col-sm-4 col-8">
                        <picture v-if="personalCandidate != null && personalCandidate != undefined && personalCandidate.EmployeeInformation != undefined">          
                            <img class="CompanyLogo img-fluid" alt="CompanyCaption" :src="'.'+ require('@/assets/img_singlepageprofile/'+ personalCandidate.CssMedia.BackgroundCompanyLogo)" onerror="this.style.display='none'" />         
                        </picture>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-2"></div>
                </div>
                <br />
                <div class="PhotoSection row" v-if=" personalCandidate.EmployeeInformation != null">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-2"></div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                        <!-- With A link-->
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12" v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length >0">                          
                            <a class="clickToWatchIntroVideo" target="_blank" :href="personalCandidate.EmployeeInformation.selfIntroVideoUrl" >
                                <div class="card-img" style="position:relative; float:left">
                                    <img class="img-fluid card-img" style="position:relative; top:0; left:0;" alt="ProfilePicture" :src="personalCandidate.imageConsultant" v-if="personalCandidate.imageConsultant.length >0" onerror="this.style.display='none'" />
                                    <img v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length >0" 
                                        class="img-fluid iconImg"
                                        style="position:absolute; right:0; bottom:0"
                                        alt="icon-play" 
                                        v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ personalCandidate.CssMedia.BackgroundPlayButton)" 
                                        onerror="style='display:none'" 
                                    />
                                </div>                      
                                <div :style="'background-image :' + personalCandidate.CssMedia.BackgroundColorIntroVideoLink">
                                    Click to Watch Intro Video
                                </div>
                            </a>
                            <br />
                        </div>
                        <!-- Without A Link-->
                        <div class="col-lg-12 col-sm-12 col-md-12 col-12" v-if="personalCandidate.EmployeeInformation.selfIntroVideoUrl.length <= 0">
                            <div class="card-img">
                                <img class="img-fluid card-img" alt="ProfilePicture" :src="personalCandidate.imageConsultant" v-if="personalCandidate.imageConsultant.length >0"  onerror="this.style.display='none'" />
                            </div>
                            <br />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-8"></div>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 NamingFont NameSection" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined">
                    <h4 class="RecruitmentName">{{personalCandidate.EmployeeInformation.displayName}}</h4>
                    <div>
                        <h4 class="RecruitmentRole">{{personalCandidate.EmployeeInformation.jobTitle}}</h4>
                    </div>
                    <div class="license" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.employmentAgencyNo.length >0">
                        EA LICENSE : {{personalCandidate.CompanyInformation.employmentAgencyNo}}
                    </div>
                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div class="row">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-sm-2 col-md-2 col-xs-2 col-2"></div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8 col-md-8 col-xs-8 col-8" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.employmentAgencyNo.length >0">
                                <hr style="opacity : 100; color : white;"/>
                            </div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-sm-8 col-md-8 col-xs-8 col-8" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.employmentAgencyNo.length <= 0">
                                <hr style="opacity : 100; color : white; margin-top:0.1em"/>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-sm-2 col-md-2 col-xs-2 col-2"></div>
                        </div>
                    </div>
                    <h4 class="RecruitmentLocation">{{personalCandidate.EmployeeInformation.cityName}}</h4>
                    <br />
                    <!-- Display NPS section -->          
                    <div v-if="personalCandidate.EmployeeInformation.npS_Weekly !== null && personalCandidate.EmployeeInformation.npS_Weekly >= 80 && personalCandidate.EmployeeInformation.totalResponses >= 10">
                        <div v-if="personalCandidate.EmployeeInformation.totalResponses !== null">
                            <div>
                                <img v-for="index in 5" :key="index" class="img-fluid RatingStars" alt="icon"
                                     v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/Gold_Star.png')"
                                     onerror="this.style.display='none'" />
                            </div>
                            <div class="npsScore">
                                <!-- Display different NPS score message for user's role above Team Leader -->
                                <div v-if="personalCandidate.EmployeeInformation.designationType < 4">
                                    {{ personalCandidate.EmployeeInformation.firstName }} and the team <br />have a combined NPS of {{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }} <br>
                                </div>
                                <div v-else>
                                    {{personalCandidate.EmployeeInformation.firstName}} has an NPS of {{ Math.floor(personalCandidate.EmployeeInformation.npS_Weekly) }}  <br>
                                </div>
                            </div>
                        </div>
                        <div class="npsScoreLine">                            
                            You’re working with one of our best! 
                        </div>
                        <div class="npsDescriptionLine">
                            Net Promoter Score (NPS) is an international measure used <br />
                            to gauge customer loyalty, satisfaction, and enthusiasm. <br />
                            Scores above 50 are considered amazing! 
                        </div>
                    </div>
                </div>
                <div class="ConsultantMainContent row">
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"></div>
                    <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-10">
                        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 xol-12">
                            <div class="GetInTouchSection">
                                <h4 class="getInTouchTitle">GET IN TOUCH</h4>
                                <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.mobileNo.length >0" >
                                    <a class="getInTouchText RecruitmentContact" :href= "'tel:'+personalCandidate.EmployeeInformation.mobileNo">
                                        <span>
                                            <img class="img-fluid iconImg"  alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'phoneiconcontact' +  '.png')"/>
                                        </span>
                                        <span style="margin-left:0.5em">
                                            <label class="textline"> + {{personalCandidate.EmployeeInformation.mobileNo}}</label>
                                        </span>
                                    </a>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.emailAddress.length >0" >
                                    <a class="getInTouchText RecruitmentContact" :href= "'mailto:'+personalCandidate.EmployeeInformation.emailAddress">
                                        <span>
                                            <img class="img-fluid iconImg" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'mailcontact' +  '.png')" />
                                        </span>
                                        <span style="margin-left:0.5em">
                                            <label class="textline">{{personalCandidate.EmployeeInformation.emailAddress}}</label>
                                        </span>
                                    </a>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 ContactDetails"
                                    v-if="personalCandidate.URLs.employeeWhatsappUrl.length >0 || personalCandidate.URLs.employeeLineUrl.length > 0 || personalCandidate.URLs.employeeLinkedInUrl.length >0">
                                    <br />
                                    <span>
                                        <a class="RecruitmentContact" target="_blank" :href="personalCandidate.URLs.employeeWhatsappUrl" v-if="personalCandidate.URLs.employeeWhatsappUrl.length >0">
                                            <img class="img-fluid iconImg" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'whatsappcontact' +  '.png')" onerror="this.style.display='none'" /> 
                                        </a>
                                    </span>
                                    <span style="margin-left:0.8em">
                                        <a class="RecruitmentContact" target="_blank" :href="personalCandidate.URLs.employeeLineUrl" v-if="personalCandidate.URLs.employeeLineUrl.length >0"> 
                                            <img class="img-fluid iconImg" alt="rounded" :src="'.' + require('@/assets/img_singlepageprofile/'+ 'line_icon' +  '.png')" onerror="this.style.display='none'" />
                                        </a>
                                    </span>
                                    <span style="margin-left:0.8em">
                                        <a class="RecruitmentContact" target="_blank" :href="personalCandidate.URLs.employeeLinkedInUrl" v-if="personalCandidate.URLs.employeeLinkedInUrl.length >0"> 
                                            <img class="img-fluid iconImg" alt="rounded" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'linkedincontact' +  '.png')" onerror="this.style.display='none'" /> 
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <br />
                            <div class="GetInTouchQRSection"  v-if="personalCandidate.qrCodeImage.length >0">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 ScanSaveContactText">
                                        SCAN TO SAVE CONTACT
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                                        <img class="img-fluid QRCodes"  alt="rounded" v-bind:src="personalCandidate.qrCodeImage"  />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div class="DownloadShareSection">
                                <span>
                                    <a class="btn textButton" @click="downloadVCFCard()" target="_blank" :style="'background-color :' + personalCandidate.CssMedia.BackgroundColorButtons">Save Contact</a>
                                </span>
                                <span style="margin-left:0.5em">
                                    <a class="btn textButton" @click="toggleShareOption()" :style="'background-color :' + personalCandidate.CssMedia.BackgroundColorButtons">Share</a>
                                </span>
                            </div>
                            <br />
                            <div class="Specialisation" v-if="personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation != undefined && personalCandidate.EmployeeInformation.specialisations.length >0">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h4 class="SpecialisationTitle">SPECIALISATIONS</h4>
                                </div>
                                <div>
                                    <div v-for="item in personalCandidate.EmployeeInformation.specialisations" :key="item" class="SpecialisationsText">
                                        {{ item.industry }}
                                        <ul v-if="item.roles != undefined && item.roles.length >0" class="SubSpecialisationsText">
                                            <li v-for="(role) in item.roles" :key="role">
                                                {{ role }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div class="ThoughtLeadership" v-if="personalCandidate != null && personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation.userProfileLinks.length >0">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h4 class="ThoughtLeadershipTitle">THOUGHT LEADERSHIP</h4>
                                </div>
                                <div class="table-responsive-sm">
                                    <table class="table" style="border:transparent">
                                        <tbody>
                                            <tr v-for=" item in personalCandidate.EmployeeInformation.userProfileLinks" :key="item">
                                                <td scope="col-2 col-lg-2 col-sm-2 col-xs-2"><img class="img-fluid iconImg"  alt="icon" :src="'.'+ require('@/assets/img_singlepageprofile/'+ 'THOTs.png')" onerror="this.style.display='none'" /></td>
                                                <td scope="col">
                                                    <a class="ThoughLeaderShipContent" :href="item.linkUrl" target="_blank">
                                                        {{ item.linkTitle }}
                                                    </a> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br />
                            <div v-if="personalCandidate != null && personalCandidate.EmployeeInformation != null && personalCandidate.EmployeeInformation.webProfileUrl != undefined && personalCandidate.EmployeeInformation.webProfileUrl.length >0">
                                <a :href="personalCandidate.EmployeeInformation.webProfileUrl" target="_blank" style="font-weight: bold;text-decoration: none;">
                                    <span><img class="img-fluid iconImg"  alt="rounded" :src="'.'+ require('@/assets/img_singlepageprofile/'+ 'click.png')" onerror="this.style.display='none'" /></span>
                                    <span style="margin-left: 0.5em;"><label class="ViewFullProfile">View Full Profile</label></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="FollowUSContent">
                    <h4 class="FollowUSTitle">Follow Us</h4>
                    <div>&nbsp;</div>
                    <div class="row">
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                        <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <div class="row">
                                <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-xl-3 col-lg-3 col-xxl-3" v-if="personalCandidate.URLs.companyUrl.length>0">
                                    <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyUrl">
                                        <img class="img-fluid iconImg" alt="icon" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'webfollowus' +  '.png')" onerror="this.style.display='none'" /> 
                                    </a>
                                </div>
                                <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-xl-3 col-lg-3 col-xxl-3" v-if="personalCandidate.URLs.companyLinkedInUrl.length>0">
                                    <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyLinkedInUrl">
                                        <img class="img-fluid iconImg" alt="icon" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'linkedincontact' +  '.png')" onerror="this.style.display='none'" /> 
                                    </a>
                                </div>
                                <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3" v-if="personalCandidate.URLs.companyInstagramUrl.length>0">
                                    <a class="followusicon" target="_blank" :href="personalCandidate.URLs.companyInstagramUrl" >
                                        <img class="img-fluid iconImg" alt="icon" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'instagramfollowus' +  '.png')" onerror="this.style.display='none'"/> 
                                    </a>
                                </div>
                                <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3" v-if="personalCandidate.URLs.companyFacebookUrl.length>0">
                                    <a class="followusicon" tartget="_blank" :href="personalCandidate.URLs.companyFacebookUrl">
                                        <img class="img-fluid iconImg" alt="icon" v-bind:src="'data:image/png;base64' + require('@/assets/img_singlepageprofile/'+ 'facebookfollowus' +  '.png')" onerror="this.style.display='none'"/> 
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                    </div>
                </div>
                <br />
                <div class="license" v-if="personalCandidate.CompanyInformation != null && personalCandidate.CompanyInformation.companyRegistrationNo.length >0">
                    CO REG NUMBER  : {{personalCandidate.CompanyInformation.companyRegistrationNo}} 
                </div>
                <br />
            </div>
        </div>
        <div class="col-lg-3 col-sm-1 col-md-2 col-xs-1 col-1"></div>
    </div>
</template>


<script>
        import axios from "axios"
        import "../components/custom.scss"
        
        //import { ShareLine } from 'vue-share-social'
        //import { useHead } from "@vueuse/head"
        //import copy from "copy-to-clipboard"

        // setup() => beforeCreate => created => beforemount => mounted => beforeupdate => updated --> beforeUnMount -> unmounted ?
        //data() => is where all the necessary dat ais binded
        //watcher() => to target and tell the vue what to do to the targeted object(s)
        //computed() => is behaved a function to bind certain data in data() to bind into UI.

        /*
        created:first time open a page/component
        mounted: every time a page/component opened bcos averytime you open it it mounts
        method: call when you wan to do sth, like call an action/mutation or do something other than dealing with store/state
        computed: you can use returned value to bind sth in template html, some changing value that changes according to some computations. If it wont change then just bind it to a date/state
        */
        export default {
            name : 'SinglePageProfile',
            //components : {
              //ShareLine
            //},
            /*setup() {
              useHead({
                title : document.title,
                meta : [
                  { name: "description", content : "Supply Planning&amp;nbsp; Ensure information architecture supporting the Supply Chain Planning, Master Data and Analytics processes is robust and integrated across the global network. Accountability of Inventory management of consigned materials:&amp;nbsp; Planning for the right stock levels of mainly API and Bulk supporting the manufacturing and packaging of company products Reconciliation of consigned materials on a periodic basis Assuring full SOX compliance for inventory management and accounts payable" },
                  { name: "author", content: "HRnetOne Singapore" },
                  { name : "keywords", content : "Namecard, HRnetOne, PeopleSearch, Consultants, Hiring Job, Headhunting"},
                  { property: "og:site_name", content: "HRnetOne - "+ document.title },
                  { property: "og:title", content: document.title },
                  { property: "og:type", content: "website" },
                  { property: "og:description", content  : "Supply Planning&amp;nbsp; Ensure information architecture supporting the Supply Chain Planning, Master Data and Analytics processes is robust and integrated across the global network. Accountability of Inventory management of consigned materials:&amp;nbsp; Planning for the right stock levels of mainly API and Bulk supporting the manufacturing and packaging of company products Reconciliation of consigned materials on a periodic basis Assuring full SOX compliance for inventory management and accounts payable"  },
                  {
                    property: "og:url",
                    content: document.location.origin
                  },
                  { name: "twitter:card", content: "testing twitter summary" },
                  { name: "twitter:creator", content: "testing twitter creator" },
                  { name: "twitter:site", content: "testing twitter site" }
                ]
              })
            }*/
            data() {
                return {         
                    employeeData    : null,
                    companyData     : null,
                    idEmployee      : "",
                    realm           : "",
                    token           : "",                  
                    qrCodeImage     : "",
                    imageConsultant : "",
                    vcfDetails      : "",
                    axiosObject     : axios.create({
                                    baseURL : "https://api.info.hrnetgroup.com",
                                    headers : {
                                        "Content-Type" : "application/json",
                                        "Access-Control-Allow-Headers" : "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
                                        "Access-Control-Allow-Credentials" : "true"
                        }
                    })
                }
            },
            created() {
                this.idEmployee = this.$route.params.id;
                if (this.idEmployee.length <= 0)
                {
                    this.$router.push('/notfound');
                }

                switch(document.location.origin.toLowerCase()) 
                {
                    case "https://about.hrnetone.com": 
                    case "https://about.hrnetrimbun.com":
                        this.realm = "hrn";
                        break;
                    case "https://about.peoplesearch.jobs":
                    case "https://about.peoplefirst.jobs":
                        this.realm = "pps";
                        break;
                    case "https://about.recruitfirst.co":
                    case "https://about.octomate.us":
                        this.realm = "rf";
                        break;
                    case "https://about.hrnetgroup.com":
                        this.realm = "hrn";
                        break;
                    default : 
                        this.realm = "hrn";
                        break;
                }                         
            },  
            mounted() {
                this.getToken();
            },  
            methods : {
                getAuthHeader() {
                    return {
                        "Authorization" : "Bearer "+ this.token
                    }
                },
                async getToken() {                   
                    var response =  await this.axiosObject.post("/api/token", 
                    { 
                        username : "string", password : "string"
                    }).catch(error => { console.log(error) });
                    
                    if (response != undefined && response.data != undefined)
                    {
                        this.token = response.data;
                    }      
                    this.getDataProfileDetails();
                    this.downloadQRCode();
                    this.getProfileImage();
                },
                async getDataProfileDetails() {
                    await this.axiosObject.get("api/Profile/Get/"+ this.realm +"/"+ this.idEmployee, {
                        headers : this.getAuthHeader()
                    })
                    .then(x=> setTimeout(this.mappingData(x), 3000)).catch(error => { console.log(error); });
                },
                async getCompanyDetails(companyCode) {
                    setTimeout(() => {}, 20000);
                    await this.axiosObject.get("api/Profile/GetCompany/"+ this.realm +"/"+ companyCode, {
                        headers : this.getAuthHeader()}
                    ).then(x=>  {
                        if (x.data != undefined != null && x.data.statusCode == 1 && x.data.result != null && x.data.result != undefined)
                        {
                            this.companyData = x.data.result;
                        }
                    }).catch(error => { console.log(error); });
                },
                async downloadQRCode() {
                    //setTimeout(() => {}, 20000);
                    await this.axiosObject.get("api/Profile/GetVcardQRImage/"+this.realm+ "/"+ this.idEmployee, { responseType : 'arraybuffer', headers : this.getAuthHeader() })
                                  .then(response => this.qrCodeImage = "data:image/jpeg;base64, " + require("buffer").Buffer.from(response.data, "binary").toString("base64"))
                                  .catch(error => { console.log(error); });
                },
                async downloadVCFCard() {   
                    //setTimeout(() => {}, 20000);                
                    var response = await this.axiosObject.get("api/Profile/GetVCard/"+this.realm+ "/"+ this.idEmployee, {  headers : this.getAuthHeader() })
                                  .catch(error => { console.log(error); });
                    if (response != undefined && response.data != undefined && response.data.statusCode == 1)
                    {
                        /*
                        let bl = null;
                        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                        if (/windows phone|Windows/i.test(userAgent) || /android/i.test(userAgent)) {
                          bl = new Blob([response.data.result], { type : 'text/vcard;charset=utf-8' });
                        } // iOS detection from: http://stackoverflow.com/a/9039885/177710
                        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                          bl = new Blob([response.data.result], { type : 'text/x-vcard;charset=utf-8' });
                        }*/
                        let bl = new Blob([response.data.result], { type : 'text/vcard;charset=utf-8' });
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(bl);
                        link.download = this.employeeData.displayName + ".vcf";
                        link._target = '_blank';
                        link.click();
                    }
                },
                async getProfileImage() {
                    await this.axiosObject.get("api/Profile/GetProfileImage/"+this.realm+ "/"+ this.idEmployee, { responseType : 'arraybuffer', headers : this.getAuthHeader() })
                                  .then(response => this.imageConsultant = "data:image/jpeg;base64, " + require("buffer").Buffer.from(response.data, "binary").toString("base64"))
                                  .catch(error => { console.log(error); }); 
                },
                toggleShareOption() {
                    navigator.share({
                        title : this.employeeData.displayName,
                        text : "Meet "+this.employeeData.displayName+ " from "+ this.employeeData.companyName,
                        url : document.location.href
                    });
                },
                mappingData(x) {
                    if (x.data != undefined != null && x.data.statusCode == 1 && x.data.result != null && x.data.result != undefined)
                    {
                        this.employeeData = x.data.result;
                        //this.employeeData.selfIntroVideoUrl = "abc";
                        //this.employeeData.specialisations = ["INFORMATION TECHNOLOGY", "MECHANICAL ENGINEER", "IT SECURITY"];
                        //this.employeeData.webProfileUrl = [{linkUrl : "abc", linkTitle : "abc"}];
                        //this.employeeData.userProfileLinks = [{linkUrl : "abc", linkTitle : "abc"}];

                        //computation for specialisations
                        if (this.employeeData.specialisations.length >0)
                        {
                          var finalizedResult = [];
                          for (let index = 0; index < this.employeeData.specialisations.length; index++)
                          {
                             if (index == 0) 
                             {
                                var SplitWord = this.employeeData.specialisations[index].split(":");
                                var CheckingWord = SplitWord[1] != undefined ? [SplitWord[1]] : [];
                                finalizedResult.push({ industry : SplitWord[0], roles :  CheckingWord});
                             } 
                             else 
                             {
                                var splittedWord = this.employeeData.specialisations[index].split(":");
                                if (finalizedResult.findIndex(x=> x.industry == splittedWord[0]) > -1) {
                                      finalizedResult.find(x=> x.industry == splittedWord[0]).roles.push(splittedWord[1]);
                                } else {
                                    var checkingRoles = splittedWord[1] != undefined ? [splittedWord[1]] : [];
                                    finalizedResult.push({industry : splittedWord[0], roles : checkingRoles });
                                }
                              }
                          }
                          if (finalizedResult.length >0) {
                              this.employeeData.specialisations = finalizedResult;
                          }
                        }

                        document.title = this.employeeData.displayName;
                        this.$gtag.pageview("Namecard for Consultant : " + this.idEmployee + ", Realm : " + this.realm, "Singapore", document.location.href);
                        this.getCompanyDetails(this.employeeData.companyCode);
                    } else {
                        this.$router.push('/notfound');
                    }

                    let allLinks = [];
                    
                    if (this.employeeData.userProfileLinks != null && this.employeeData.userProfileLinks.length >0) {
                        allLinks.push(...this.employeeData.userProfileLinks);
                    }

                    if (this.employeeData.entityArticleLinks != null && this.employeeData.entityArticleLinks.length >0) {
                        allLinks.push(...this.employeeData.entityArticleLinks);
                    }

                    this.employeeData.userProfileLinks = allLinks;
                }             
            },
            computed : {
                personalCandidate() {
                    var BackGroundColor = "";
                    var BackgroundColorIntroVideo = "";
                    var BackgroundColorButton ="";
                    var listOfUrls = {};

                    var CompanyUrl = "";
                    var CompanyLinkedInUrl = "";
                    var CompanyInstagramUrl = "";
                    var CompanyFacebookUrl = "";
                    var EmployeeLinkedUrl = "";
                    var EmployeeWhatsappUrl = "";
                    var EmployeeLineUrl = "";
                    var CompanyLogo = "";
                    var PlayButton = "";

                    if (this.employeeData != undefined && this.employeeData.realm != undefined) 
                    {
                        switch (this.employeeData.realm.toLowerCase())
                        {
                            case "hrn":
                                BackGroundColor = "linear-gradient(45deg,#ff7e56,#fec554)";
                                BackgroundColorIntroVideo = "linear-gradient(to right, #ffc000, #faac0f, #f39322, #ed7d31 )";
                                BackgroundColorButton = "#ffb755";//"#ed7d31";
                                CompanyLogo = document.location.origin.toLowerCase() == "https://about.hrnetgroup.com" ? "hrnetgroup.png" : this.employeeData.companyCode.toLowerCase() == "hri" ? "hrn-4.png" : "hri-4.png";
                                PlayButton = "Play_Icon_Orange.png";
                                //use the second version
                                break;
                            case "rf":
                                BackGroundColor = this.employeeData.companyCode.toLowerCase() == "ots" ? 'url('+ require('@/assets/img_singlepageprofile/oct_background.jpg') +')' : "linear-gradient(45deg, #2d296e, #652879, #b624af)";
                                BackgroundColorIntroVideo = this.employeeData.companyCode.toLowerCase() == "ots" ? "linear-gradient(to right, #006C70, #009EA4, #00BDC3)" : "linear-gradient(to right, #eeb66d, #e2957f, #cd6485, #c23487)";
                                BackgroundColorButton = this.employeeData.companyCode.toLowerCase() == "ots" ? "#0C879C" : "#a12386";
                                CompanyLogo = this.employeeData.companyCode.toLowerCase() == "ots" ? "octomate.png": "rf-3.png";
                                PlayButton = this.employeeData.companyCode.toLowerCase() == "ots" ? "Play_Icon_Green.png":"Play_Icon_purple.png";
                                break;
                            case "pps":
                                BackGroundColor = 'url('+ require('@/assets/img_singlepageprofile/5mb_pp_background_vertical.png') +')';
                                BackgroundColorIntroVideo = "linear-gradient(to right, #223c6a, #426fc0, #30579a)";
                                BackgroundColorButton = "#203864";
                                CompanyLogo = this.employeeData.companyCode.toLowerCase() == "pfmy" ? "ppmy.png" : "pps.png";
                                PlayButton = "Play_Icon_Blue.png";
                                break;
                        }

                        EmployeeWhatsappUrl = (this.employeeData.whatsAppUrl.length > 0 ? this.employeeData.whatsAppUrl : "");
                        EmployeeLinkedUrl = (this.employeeData.linkedInUrl.length > 0 ? this.employeeData.linkedInUrl : "");
                        EmployeeLineUrl   = (this.employeeData.lineUrl != undefined && this.employeeData.lineUrl.length >0 ? this.employeeData.lineUrl : "");
                    }

                    if (this.companyData != null) 
                    {
                        CompanyUrl = ((this.companyData != null && this.companyData.websiteUrl != undefined && this.companyData.websiteUrl.length >0) ? this.companyData.websiteUrl : "");
                        CompanyLinkedInUrl = ((this.companyData != null && this.companyData.linkedInUrl != undefined && this.companyData.linkedInUrl.length >0) ? this.companyData.linkedInUrl : "");
                        CompanyInstagramUrl = ((this.companyData != null && this.companyData.instagramUrl != undefined && this.companyData.instagramUrl.length >0) ? this.companyData.instagramUrl : "");
                        CompanyFacebookUrl = ((this.companyData != null && this.companyData.facebookUrl != undefined && this.companyData.facebookUrl.length >0) ? this.companyData.facebookUrl : "");
                    }

                    listOfUrls = 
                    {  
                        companyInstagramUrl : CompanyInstagramUrl,
                        companyFacebookUrl  : CompanyFacebookUrl,
                        companyLinkedInUrl  : CompanyLinkedInUrl,
                        companyUrl          : CompanyUrl,
                        employeeWhatsappUrl  : EmployeeWhatsappUrl, 
                        employeeLinkedInUrl  :  EmployeeLinkedUrl,
                        employeeLineUrl      : EmployeeLineUrl
                    }

                    return {
                        EmployeeInformation : this.employeeData,
                        CompanyInformation : this.companyData,
                        CssMedia : {
                            BackgroundColorCss : BackGroundColor,
                            BackgroundColorIntroVideoLink : BackgroundColorIntroVideo,
                            BackgroundColorButtons : BackgroundColorButton,
                            BackgroundCompanyLogo : CompanyLogo,
                            BackgroundPlayButton : PlayButton,
                        },
                        Realm : this.realm,
                        URLs : listOfUrls,
                        webShareAPiSupported : navigator.share,
                        qrCodeImage : this.qrCodeImage,
                        imageConsultant : this.imageConsultant
                    }
                }
            }
        }
</script>
