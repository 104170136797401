import { createRouter , createWebHistory } from 'vue-router'
import singlepageprofile from './components/singlepageprofile.vue';
import NotFound from './components/notfound.vue';

const router = createRouter ({
    history : createWebHistory(),
    routes : [
        { name : 'id' , path : '/:id(.*)', component : singlepageprofile, sensitive : true}, // '/:id ([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-...)'
        { path : '/:pathMatch(.*)', component : NotFound}, //to match any route (.*)
        { path : '/notfound', component : NotFound }
        //{ path : '/', component : singlepageprofile},
    ]
})

export default router;